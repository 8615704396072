
import { mapGetters } from 'vuex'
import filters from "@/mixins/filters";

export default {
	mixins: [filters],
	computed: {
		...mapGetters({
			settings: 'settings/settings/settings',
			allMarks: 'marks/marks/allMarks',

		})
	},
	data() {
		return {
			links: [
				{ to: '/cars', text: 'каталог' },
				{ to: '/credit', text: 'автокредит' },
				{ to: '/exchange', text: 'trade-in' },
				{ to: '/installments', text: 'рассрочка' },
				{ to: '/buyout', text: 'выкуп' },
				{ to: '/banks', text: 'банки-партнеры' },
				{ to: '/contacts', text: 'контакты' },
				{ to: '/about', text: 'об автосалоне' },
			]
		}
	}
}
